import React from 'react';
import { array, string } from 'prop-types';
import { Controller, Scene } from 'react-scrollmagic';
import classNames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-modal-routing';
import { ImageWrap, ListingFiltersGm003, Newsletter } from 'components';
import './styles.scss';
import { getLocalePrefix, t, getCurrentLocale } from '../../utils/lang-helper';

class StoriesGrid extends React.Component {
  static propTypes = {
    grid: array.isRequired,
    routedTag: string
  };

  static defaultProps = {
    routedTag: null
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      itemsToShow: 7,
      showStories: 5,
      prerenderItems: props.grid
        .filter(
          el =>
            el.node.data.lister_title &&
            el.node.data.lister_image.fluid &&
            el.node.uid.includes('nnn111') === false &&
            el.node.lang === getCurrentLocale()
        )
        .sort((a, b) =>
          Number(
            a.node.first_publication_date
              .slice(0, 10)
              .replace(/[-]+/g, '')
          ) <
          Number(
            b.node.first_publication_date
              .slice(0, 10)
              .replace(/[-]+/g, '')
          )
            ? 1
            : -1
        )
    };
  }

  loadMoreStories = () => {
    this.setState(prev => {
      return {
        itemsToShow: prev.itemsToShow + this.state.showStories
      };
    });
  };

  filterHandler = value => {
    const { filters } = this.state;
    if (filters.indexOf(value) === -1) {
      filters.push(value);
      this.setState({ filters });
    } else {
      const remaining = filters.filter(el => el !== value);
      this.setState({ filters: remaining });
    }
  };

  routedHandler = value => {
    const { filters } = this.state;
    if (value === null) {
      return;
    }
    filters.length = 0;
    filters.push(value);
    this.setState({ filters });
  };

  render() {
    const { filters, itemsToShow, prerenderItems } = this.state;
    const items =
      filters.length === 0
        ? prerenderItems
        : prerenderItems.filter(el =>
          el.node.data.article_tags.some(
            n => n.tag && filters.indexOf(n.tag.uid) !== -1
          )
        );
    let i = 0;
    return (
      <>
        <ListingFiltersGm003
          filterHandler={this.filterHandler}
          routedHandler={this.routedHandler}
          routedTag={this.props.routedTag}
        />
        <section className="StoriesGrid StoriesGridWithFilter">
          {items.slice(0, itemsToShow).map(el => (
            <div key={el.node.uid} iteration={i++}>
              <Controller>
                <Scene classToggle="is-visible" triggerHook={1}>
                  <div
                    className={classNames(
                      'StoriesGrid-contentBox',
                      {
                        isHighlight:
                          el.gm1_highlight ===
                          'Yes' ||
                          el.node.data
                            .lister_dark_bg ===
                          'Yes'
                      }
                    )}
                  >
                    <Link
                      className="StoriesGrid-imageLink"
                      to={getLocalePrefix() + el.node.uid}
                      asModal
                    >
                      <ImageWrap
                        className="StoriesGrid-image"
                        image={
                          el.node.data.lister_image
                        }
                      />
                    </Link>
                    <div className="StoriesGrid-textBox">
                      <Link
                        to={
                          getLocalePrefix() +
                          el.node.uid
                        }
                        asModal
                      >
                        <h3 className="StoriesGrid-subTitle">
                          {el.node.data.lister_title}
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                            el.node.data
                              .lister_description
                              .html
                          }}
                        />
                      </Link>
                      <Link
                        className="StoriesGrid-link"
                        to={
                          getLocalePrefix() +
                          el.node.uid
                        }
                        asModal
                      >
                        {t('Read the article')}
                      </Link>
                    </div>
                  </div>
                </Scene>
              </Controller>
              {i % 4 === 0 && (
                <Controller>
                  <Scene
                    classToggle="is-visible"
                    triggerHook={1}
                  >
                    <div className="StoriesGrid-contentBox isNewsletterBlock">
                      {/*<Newsletter*/}
                      {/*  gainBefore={t(*/}
                      {/*    'Sign up to our mailing list to receive latest stories, news, events and much more.',*/}
                      {/*  )}*/}
                      {/*  gainAfter={t(*/}
                      {/*    'Congratulations! You are now subscribed to ISKO’s newsletter',*/}
                      {/*  )}*/}
                      {/*  titleBefore={t(*/}
                      {/*    'Never miss a story',*/}
                      {/*  )}*/}
                      {/*  titleAfter={t('Thank you')}*/}
                      {/*  btnText={t('Reset')}*/}
                      {/*  className='is-inGrid'*/}
                      {/*/>*/}
                    </div>
                  </Scene>
                </Controller>
              )}
            </div>
          ))}
          {items.length > itemsToShow ? (
            <div className="StoriesGrid-viewAllButton">
              <button
                onClick={this.loadMoreStories}
                type="button"
              >
                {t('LOAD MORE')}
              </button>
            </div>
          ) : null}
        </section>
      </>
    );
  }
}

const StoriesGridWithFilter = props => (
  <StaticQuery
    query={graphql`
      {
        all: allPrismicStory {
          edges {
            node {
              uid
              first_publication_date
              lang
              data {
                article_tags {
                  tag {
                    uid
                  }
                }
                lister_description {
                  html
                }
                lister_title
                lister_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                lister_dark_bg
              }
            }
          }
        }
      }
    `}
    render={({ all: { edges } }) => <StoriesGrid grid={edges} {...props} />}
  />
);

export default StoriesGridWithFilter;
