import React from 'react';
import { object } from 'prop-types';
import Layout from '../components/Layout';
import { allComponents } from '../components';
import StoriesGridWithFilter from '../components/StoriesGridWithFilter';
import { withPreview } from 'gatsby-source-prismic';
import ContextualEnquiryFormCfm001 from '../components/ContextualEnquiryFormCfm001';
import LargeSignpostModuleSm004 from '../components/LargeSignpostModuleSm004';
import FullWidthSignpostCarouselSm008 from '../components/FullWidthSignpostCarouselSm008';

const StoriesConstructor = ({
  pageContext: { names, pageLang, newsletter },
  location: { state },
  data
}) => {
  if (data && data.prismicStories) {
    // Preview data is loaded via proxy and we need to loop through it to get it loaded
    names = data.prismicStories.data.body; // Inject it back into the main component array
  }

  const carouselData = names.find(item => item.__typename === 'PrismicStoriesBodyModuleIdSm008');
  const signData = names.find(item => item.__typename === 'PrismicStoriesBodyModuleIdSm004');
  const contextualData = names.find(item => item.__typename === 'PrismicStoriesBodyModuleIdCfm001');

  const FullWidthSignpostCarousel = allComponents['Sm008'];
  const LargeSignpost = allComponents['Sm004'];
  const ContextualEnquiryForm = allComponents['fm001'];
  const tag = state && state.tag;

  return (
    <Layout pageLang={pageLang} newsletter={newsletter}>
      {carouselData && <FullWidthSignpostCarousel items={carouselData.items} pageLang={pageLang} />}

      <StoriesGridWithFilter routedTag={tag} pageLang={pageLang} />

      {contextualData && <ContextualEnquiryForm primary={contextualData.primary} pageLang={pageLang} />}

      {signData && <LargeSignpost primary={signData.primary} pageLang={pageLang} />}
    </Layout>
  );
};

StoriesConstructor.propTypes = {
  pageContext: object.isRequired,
  location: object.isRequired
};

export default withPreview(StoriesConstructor);
